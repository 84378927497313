import React from 'react';
import { TermView } from 'views/term';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags-lexicon';
import { graphql } from 'gatsby';
import { Layout } from 'layouts';
import { object } from 'prop-types';
import parse from 'html-react-parser'; // Import html-react-parser
import { BannerMonthlyPromo } from '../views/offer/banner-monthly-promo';
import { RecommendationsSection } from '../views/home/recommendations-section';



const TermPage = ({ data }) => {
  const { markdownRemark } = data;
  const {
    frontmatter: { title, pageId },
    html,
  } = markdownRemark;

  // Use html-react-parser to inject the BannerMonthlyPromo component
  const parsedHtml = parse(html, {
    replace: (domNode) => {
      console.log("Processing node:", domNode.name || domNode.data);
      // Ensure domNode is an element before proceeding
      if (domNode.type === 'tag' && domNode.name === 'bannerpromo') {
        return <BannerMonthlyPromo />;
      }
      if (domNode.type === 'tag' && domNode.name === 'recommendations') {
        return <RecommendationsSection />;
      }

      return undefined; // Let the parser handle other nodes recursively
    },
  });



  return (
    <Layout>
      {/* Pass JSX content (parsedHtml) directly to the TermView component */}
      <TermView title={title} html={parsedHtml} pageId={pageId} />
    </Layout>
  );
};

export default TermPage;

export const Head = ({ data }) => {
  const { markdownRemark } = data;
  const {
    frontmatter: { pageId },
  } = markdownRemark;

  return <SEO tags={TAGS[pageId]} />;
};

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        pageId
      }
    }
  }
`;

TermPage.propTypes = {
  data: object.isRequired,
};

