import React from 'react';
import { TextWrapper } from 'views/term/components/TextWrapper';
import { BackButton } from 'components/back-button';
import { ContentWrapper } from 'components/content-wrapper';
import { OtherTreatments } from 'components/other-treatments';
import { PageHeader } from 'components/page-header';
import { PageWrapper } from 'components/page-wrapper';
import { LINKS } from 'constants/links';
import { PATHS } from 'constants/paths';
import OFFERS from 'jsons/recommended.json';
import { string, node } from 'prop-types'; // Import PropTypes.node for React nodes support

export const TermView = ({ title, html, pageId }) => {
  return (
    <PageWrapper>
      <PageHeader links={[LINKS.LEXICON]}>{title}</PageHeader>
      <ContentWrapper>
        <TextWrapper>
          <div>{html}</div>
        </TextWrapper>
        <BackButton to={PATHS.LEXICON} />
      </ContentWrapper>
      {!!OFFERS[pageId] && OFFERS[pageId].length > 0 && (
        <OtherTreatments offers={OFFERS[pageId]} title="Polecane zabiegi" />
      )}
    </PageWrapper>
  );
};

TermView.propTypes = {
  title: string.isRequired, // Keep as a string
  html: node.isRequired, // Change from string to node to support React components or HTML
  pageId: string.isRequired, // Keep as a string
};